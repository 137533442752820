import { get, filter, find, isEqual, isNumber } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import {
  AUTHOR_INFO_TYPE_MOBILE_PATH,
  AUTHOR_INFO_TYPE_PATH,
  CARD_BORDER_WIDTH_PATH,
  getGenericPostFontPath,
  getIsSocialSharingProviderEnabledParamPath,
  getPostFontSizesPath,
  getSBSLayoutImagePositionParamPath,
  getLayoutPostSizeParamPath,
  getLayoutSpacingParamPath,
  getLayoutContentHeightParamPath,
  getLayoutContentHeightTypeParamPath,
  getLayoutImageRatioParamPath,
  getLayoutSidesPaddingParamPath,
  getLayoutMarginsParamPath,
  getLayoutTitleLineCountParamPath,
  getLayoutDescriptionLineCountParamPath,
  getLayoutImageProportionsParamPath,
  getLayoutImageAlignmentParamPath,
  getFeedBorderColorPath,
  getMobileFeedBorderColorPath,
  getLayoutCardsRowTypeParamPath,
  getLayoutPostsPerRowParamPath,
  IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
  IS_AUTHOR_NAME_ENABLED_PATH,
  IS_BANNER_ENABLED_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
  IS_BLOG_MENU_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_ENABLED_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_PATH,
  IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_CATEGORY_LABELS_ENABLED_PATH,
  IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
  IS_COMMENT_COUNT_ENABLED_PATH,
  IS_COMMENTS_ENABLED_MOBILE_PATH,
  IS_COMMENTS_ENABLED_PATH,
  IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
  IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
  IS_LIKE_COUNT_ENABLED_PATH,
  IS_PINTEREST_ENABLED_PATH,
  IS_TWITTER_ENABLED_PATH,
  IS_POST_DATE_ENABLED_MOBILE_PATH,
  IS_POST_DATE_ENABLED_PATH,
  IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
  IS_POST_DESCRIPTION_ENABLED_PATH,
  IS_POST_LIST_FULL_WIDTH_ENABLED_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_PATH,
  IS_POST_TITLE_ENABLED_MOBILE_PATH,
  IS_POST_TITLE_ENABLED_PATH,
  IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
  IS_POST_UPDATED_DATE_ENABLED_PATH,
  IS_READING_TIME_ENABLED_MOBILE_PATH,
  IS_READING_TIME_ENABLED_PATH,
  IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
  IS_RECENT_POSTS_ENABLED_PATH,
  IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
  IS_SOCIAL_SHARING_ENABLED_PATH,
  IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
  IS_VIEW_COUNT_ENABLED_PATH,
  POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
  POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
  POST_PAGE_FONT_SIZE_MOBILE_PATH,
  POST_QUOTES_FONT_SIZE_MOBILE_PATH,
  POST_TITLE_FONT_SIZE_MOBILE_PATH,
  SECTION_CATEGORY,
  IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_PICTURE_ENABLED_PATH,
  IS_AUTHOR_BADGE_ENABLED_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_PATH,
  IS_TAGS_ENABLED_PATH,
  IS_TAGS_ENABLED_MOBILE_PATH,
  getLayoutImageCropTypeParamPath,
  SECTION_POST_LIST,
  IS_TAG_LABEL_ENABLED_PATH,
  TAG_LAYOUT_PATH,
  DEFAULT_TAG_LAYOUT,
  TAG_DISPLAY_PARAMS,
  getTagLayoutParams,
  getLayoutTypeById,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  isLayoutNamePGSideBySide,
  isLayoutNamePGOneColumn,
  IS_BANNER_ENABLED_DEFAULT,
  getDefaultFontSize,
  getMaxFontSize,
  getMinFontSize,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO,
  DEFAULT_PG_LAYOUT_ITEM_SIZE,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
  DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE,
  DEFAULT_PG_LAYOUT_SPACING,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
  DEFAULT_PG_LAYOUT_MARGINS,
  DEFAULT_PG_IMAGE_ALIGNMENT,
  DEFAULT_PG_IMAGE_PROPORTIONS,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
  getPGLayoutSettingsConstant,
  SOCIAL_SHARING_PROVIDERS,
  RELATED_POSTS_LABEL_OPTIONS,
  RELATED_POSTS_LABEL_STRING_KEY,
  RELATED_POSTS_LABEL_PATH,
  RELATED_POSTS_LABEL_MOBILE_PATH,
  IS_RELATED_POSTS_ENABLED_PATH,
  IS_RELATED_POSTS_ENABLED_MOBILE_PATH,
  IS_RELATED_POSTS_LABEL_ENABLED_MOBILE_PATH,
  IS_RELATED_POSTS_LABEL_ENABLED_PATH,
  IS_CATEGORY_HEADER_ENABLED_PATH,
  IS_CONFIGURABLE_LINE_CLAMPING_ENABLED_PATH,
  DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT,
  DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT,
  TAG_CLOUD_MOBILE_PARAMS,
  TAG_DISPLAY_PARAMS_MOBILE,
  TAG_LAYOUT_MOBILE_PATH,
  IS_CATEGORY_LABEL_ENABLED_PATH,
  IS_CATEGORY_LABEL_ENABLED_MOBILE_PATH,
  DEFAULT_PG_LAYOUT_SPACING_MOBILE,
  DEFAULT_PG_LAYOUT_MARGINS_MOBILE,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE,
  getFeedMetadataSettingsDefaultValue,
  SECTION_MY_POSTS,
  IS_COMMENT_COUNT_ENABLED,
  IS_LIKE_COUNT_ENABLED,
  IS_CATEGORY_LABELS_ENABLED,
  IS_TAGS_ENABLED,
  IS_POST_TITLE_ENABLED,
  IS_POST_DESCRIPTION_ENABLED,
  IS_MORE_OPTIONS_MENU_ENABLED,
  IS_VIEW_COUNT_ENABLED,
  IS_AUTHOR_PICTURE_ENABLED,
  IS_POST_DATE_ENABLED,
  IS_POST_UPDATED_DATE_ENABLED,
  IS_CATEGORY_LABEL_ENABLED,
  IS_AUTHOR_BADGE_ENABLED,
  IS_AUTHOR_NAME_ENABLED,
  AUTHOR_INFO_TYPE,
  IS_POST_PUBLISH_DATE_ENABLED,
  SOCIAL_SHARING_PRINT,
  IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE_PATH,
  IS_SEE_ALL_POSTS_LINK_ENABLED_PATH,
  MY_POSTS_PAGE_BORDER_WIDTH,
  MY_POSTS_PAGE_BORDER_WIDTH_DEFAULT_VALUE,
  CONTENT_ALIGNMENT_PATH,
  CONTENT_ALIGNMENT_MOBILE_PATH,
  CONTENT_ALIGNMENTS,
  DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE,
  DEFAULT_PG_LAYOUT_POSTS_PER_ROW,
  POST_TITLE_FONT_PATH,
  IS_POST_DESIGN_IN_FULL_POST_ENABLED_PATH,
  DEFAULT_PG_IMAGE_RESIZING_MODE,
  DEFAULT_PG_IMAGE_WIDTH,
  getLayoutImageResizingModeParamPath,
  getLayoutImageWidthParamPath,
} from '@wix/communities-blog-client-common';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import {
  DEFAULT_POST_PAGE_FONT_SIZE_MOBILE,
  DEFAULT_POST_TITLE_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  DEFAULT_POST_QUOTES_SIZE_MOBILE,
} from '../../post-page/constants/post-page';
import { getLayoutType } from './layout-selectors';

import { getSection } from './section-selectors';
import { getAppSettings, getAppSettingsNumber, getAppSettingsValue } from './app-settings-base-selectors';
import { getPostActions } from '../services/post-actions';
import { secondParam } from '../services/param-selectors';
import {
  getIsMobileDisplaySettingsEnabled,
  getIsMobileSettingEnabled,
  getIsMobileLayoutSettingsEnabled,
  getIsMobileDesignSettingsEnabled,
} from './mobile-settings-selectors';

const NOT_DEFINED = 'undefined_';
export const isDefinedAppSettingsValue = (state, key) =>
  getAppSettingsValue({ state, key, fallback: NOT_DEFINED }) !== NOT_DEFINED;

export const getAppSettingsPresets = (state) => state.appSettingsPresets;

export const getStyle = (state) => getAppSettingsValue({ state, key: 'style', fallback: {} });

export const getCustomAppSettings = (state) => getAppSettingsValue({ state, key: 'custom', fallback: {} });

export const getLastUpdatedDate = (state) =>
  getAppSettingsValue({ state, key: 'updatedAt', fallback: new Date().getTime() });

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getColorPresets = createDeepEqualSelector([getAppSettings], (appSettings) =>
  get(appSettings, 'colorPresets', []),
);

export const getSiteColors = createDeepEqualSelector([getColorPresets], (colorPresets) =>
  (colorPresets || []).reduce((acc, { reference, value }) => {
    acc[reference] = value;
    return acc;
  }, {}),
);

export const getUseMobileDisplaySettings = (state) => getIsMobile(state) && getIsMobileDisplaySettingsEnabled(state);
export const getUseMobileLayoutSettings = (state) => getIsMobile(state) && getIsMobileLayoutSettingsEnabled(state);
export const getUseMobileDesignSettings = (state) => getIsMobile(state) && getIsMobileDesignSettingsEnabled(state);

const getDisplayToggle = ({ state, wixParam, key, mobileKey, fallback }) => {
  fallback = fallback ?? getFeedMetadataSettingsDefaultValue(wixParam, getSection(state));

  return getAppSettingsValue({
    state,
    key: getUseMobileDisplaySettings(state) ? mobileKey : key,
    fallback,
  });
};

export const getIsCommentCountEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
    key: IS_COMMENT_COUNT_ENABLED_PATH,
    wixParam: IS_COMMENT_COUNT_ENABLED,
  });

export const getIsLikeCountEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
    key: IS_LIKE_COUNT_ENABLED_PATH,
    wixParam: IS_LIKE_COUNT_ENABLED,
  });

export const getIsCategoryLabelsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_CATEGORY_LABELS_ENABLED_PATH,
    wixParam: IS_CATEGORY_LABELS_ENABLED,
  });

export const getIsTagsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_TAGS_ENABLED_MOBILE_PATH,
    key: IS_TAGS_ENABLED_PATH,
    wixParam: IS_TAGS_ENABLED,
  });

export const getIsMoreButtonEnabled = (state, showMoreButton) => getIsMoreOptionsMenuEnabled(state) && showMoreButton;

export const getIsPostTitleEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_TITLE_ENABLED_MOBILE_PATH,
    key: IS_POST_TITLE_ENABLED_PATH,
    wixParam: IS_POST_TITLE_ENABLED,
  });

export const getIsPostDescriptionEnabled = (state, section) => {
  const mobileDisplaySettingsEnabled = getIsMobileDisplaySettingsEnabled(state);
  const isMobile = getIsMobile(state);

  if (getSection(state) === SECTION_MY_POSTS) {
    return getFeedMetadataSettingsDefaultValue(IS_POST_DESCRIPTION_ENABLED, SECTION_MY_POSTS);
  }

  if (section !== SECTION_POST_LIST && !mobileDisplaySettingsEnabled && isMobile) {
    return true;
  }

  return getDisplayToggle({
    state,
    mobileKey: IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
    key: IS_POST_DESCRIPTION_ENABLED_PATH,
    wixParam: IS_POST_DESCRIPTION_ENABLED,
  });
};

export const getIsMoreOptionsMenuEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
    key: IS_MORE_OPTIONS_MENU_ENABLED_PATH,
    wixParam: IS_MORE_OPTIONS_MENU_ENABLED,
  });

export const getIsViewCountEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
    key: IS_VIEW_COUNT_ENABLED_PATH,
    wixParam: IS_VIEW_COUNT_ENABLED,
  });

export const getIsAuthorNameEnabled = (state) => {
  const fallback = getFeedMetadataSettingsDefaultValue(IS_AUTHOR_NAME_ENABLED, getSection(state));
  const useMobileSettings = getUseMobileDisplaySettings(state);

  return getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_NAME_ENABLED_PATH,
    fallback: useMobileSettings ? getAppSettingsValue({ state, key: IS_AUTHOR_NAME_ENABLED_PATH, fallback }) : fallback,
  });
};

export const getIsAuthorPictureEnabled = (state, fallback) =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_PICTURE_ENABLED_PATH,
    wixParam: IS_AUTHOR_PICTURE_ENABLED,
    fallback,
  });

export const getIsAuthorBadgeEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_BADGE_ENABLED_PATH,
    fallback: getUseMobileDisplaySettings(state)
      ? getIsAuthorNameEnabled(state)
      : getFeedMetadataSettingsDefaultValue(IS_AUTHOR_BADGE_ENABLED, getSection(state)),
  });

export const getAuthorInfoType = (state) => {
  const fallback = getFeedMetadataSettingsDefaultValue(AUTHOR_INFO_TYPE, getSection(state));

  return getDisplayToggle({
    state,
    key: AUTHOR_INFO_TYPE_PATH,
    mobileKey: AUTHOR_INFO_TYPE_MOBILE_PATH,
    fallback: getUseMobileDisplaySettings(state)
      ? getAppSettingsValue({ state, key: AUTHOR_INFO_TYPE_PATH, fallback })
      : fallback,
  });
};

export const getIsPostDateEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_DATE_ENABLED_PATH,
    wixParam: IS_POST_DATE_ENABLED,
  });

export const getIsPostUpdatedDateEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_UPDATED_DATE_ENABLED_PATH,
    wixParam: IS_POST_UPDATED_DATE_ENABLED,
  });

export const getIsPostListFullWidthEnabled = (state) =>
  getAppSettingsValue({ state, key: IS_POST_LIST_FULL_WIDTH_ENABLED_PATH, fallback: false });

export const getIsFeedDesignPropsMigrated = (state) =>
  getAppSettingsValue({ state, key: IS_FEED_DESIGN_PROPS_MIGRATED_PATH, fallback: false });

export const getIsPostPublishDateEnabled = (state) => {
  const fallback =
    getSection(state) === SECTION_MY_POSTS
      ? getFeedMetadataSettingsDefaultValue(IS_POST_PUBLISH_DATE_ENABLED, SECTION_MY_POSTS)
      : getIsPostDateEnabled(state);

  return getDisplayToggle({
    state,
    mobileKey: IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_PUBLISH_DATE_ENABLED_PATH,
    fallback,
  });
};

export const getIsReadingTimeEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_READING_TIME_ENABLED_MOBILE_PATH,
    key: IS_READING_TIME_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  });

export const getCardBorderWidth = (state) => getAppSettingsValue({ state, key: CARD_BORDER_WIDTH_PATH, fallback: 1 });

export const getIsBlogMenuEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuCategoryLabelsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuSearchEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_SEARCH_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuLoginButtonEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
    fallback: !getIsMemberAreaInstalled(state),
  });

export const getIsRecentPostsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
    key: IS_RECENT_POSTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsRelatedPostsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_RELATED_POSTS_ENABLED_MOBILE_PATH,
    key: IS_RELATED_POSTS_ENABLED_PATH,
  });

export const getIsSeeAllPostsLinkEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE_PATH,
    key: IS_SEE_ALL_POSTS_LINK_ENABLED_PATH,
  });

export const getRelatedPostsLabel = (state) => {
  const isLabelEnabled = getDisplayToggle({
    state,
    mobileKey: IS_RELATED_POSTS_LABEL_ENABLED_MOBILE_PATH,
    key: IS_RELATED_POSTS_LABEL_ENABLED_PATH,
    fallback: true,
  });
  const labelOption = getAppSettingsValue({
    state,
    key: getUseMobileDisplaySettings(state) ? RELATED_POSTS_LABEL_MOBILE_PATH : RELATED_POSTS_LABEL_PATH,
    fallback: RELATED_POSTS_LABEL_OPTIONS.recent,
  });

  return isLabelEnabled ? RELATED_POSTS_LABEL_STRING_KEY[labelOption] : undefined;
};

export const getIsCommentsEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENTS_ENABLED_MOBILE_PATH,
    key: IS_COMMENTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsSocialSharingEnabled = (state) =>
  getDisplayToggle({
    state,
    mobileKey: IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
    key: IS_SOCIAL_SHARING_ENABLED_PATH,
    fallback: true,
  });

export const getIsPrintEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(SOCIAL_SHARING_PRINT, getUseMobileDisplaySettings(state)),
    fallback: false,
  });

export const getIsSocialSharingProviderEnabled = (state, provider) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(provider, getUseMobileDisplaySettings(state)),
    fallback: true,
  });

const getEnabledProviders = (state) =>
  filter(SOCIAL_SHARING_PROVIDERS, (provider) => getIsSocialSharingProviderEnabled(state, provider));

export const getSocialSharingProviders = createDeepEqualSelector(
  [getIsSocialSharingEnabled, getEnabledProviders],
  (enabled, providers) => (enabled ? providers : []),
);

export const getPostTitleFont = (state) => getAppSettingsValue({ state, key: POST_TITLE_FONT_PATH });
export const getPostTitleFontSizeMobile = (state) =>
  getAppSettingsValue({ state, key: POST_TITLE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_TITLE_FONT_SIZE_MOBILE });
export const getPostPageFontSizeMobile = (state) =>
  getAppSettingsValue({ state, key: POST_PAGE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_PAGE_FONT_SIZE_MOBILE });
export const getPostHeaderTwoFontSizeMobile = (state) =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  });
export const getPostHeaderThreeFontSizeMobile = (state) =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  });
export const getPostQuotesFontSizeMobile = (state) =>
  getAppSettingsValue({
    state,
    key: POST_QUOTES_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_QUOTES_SIZE_MOBILE,
  });

const getDesktopFontSize = (state, section, font) =>
  getAppSettingsValue({ state, key: `${getGenericPostFontPath(section, font)}.size` });

export const getFontSize = (state, layoutType, section, contentType) => {
  const stylePath = getPostFontSizesPath(layoutType, section || SECTION_CATEGORY, `${contentType}FontSize`);
  const defaultSize = getDefaultFontSize(layoutType, contentType);

  if (getIsMobile(state)) {
    if (section === SECTION_POST_LIST) {
      const mobileLayout = getLayoutType(state, section, true);
      const mobileStylePath = getPostFontSizesPath(mobileLayout, section, `${contentType}FontSize`, true);
      return get(getAppSettings(state), mobileStylePath, get(getAppSettings(state), stylePath, defaultSize));
    }
    const maxFontSize = getMaxFontSize(layoutType, contentType);
    const minFontSize = getMinFontSize(layoutType, contentType);
    let fallback = getDesktopFontSize(state, section, `${contentType}Font`) || defaultSize;
    fallback = Math.min(maxFontSize, fallback) || fallback;
    fallback = Math.max(minFontSize, fallback) || fallback;
    return get(getAppSettings(state), stylePath, fallback);
  }

  return get(getAppSettings(state), stylePath, defaultSize);
};

export const getPinterestEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_PINTEREST_ENABLED_PATH,
    fallback: true,
  });

export const getTwitterEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_TWITTER_ENABLED_PATH,
    fallback: false,
  });

export const getSBSLayoutImagePosition = (state, section) =>
  getAppSettingsValue({
    state,
    key: getSBSLayoutImagePositionParamPath(section),
    fallback: SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  });

export const getIsFeaturedPostBannerEnabled = (state) =>
  getAppSettingsValue({ state, key: IS_BANNER_ENABLED_PATH, fallback: IS_BANNER_ENABLED_DEFAULT });

export const getIsCategoryHeaderEnabled = (state) =>
  getAppSettingsValue({ state, key: IS_CATEGORY_HEADER_ENABLED_PATH, fallback: false });

export const getIsCreatedWithResponsiveEditor = (state) =>
  get(getAppSettings(state), 'style.booleans.responsive', false);

export const getMyPostsPageBorderWidth = (state) =>
  getAppSettingsNumber(state, MY_POSTS_PAGE_BORDER_WIDTH, MY_POSTS_PAGE_BORDER_WIDTH_DEFAULT_VALUE);

const PG_SIDE_BY_SIDE_LAYOUT_SIZE = 1100;
const PG_ONE_COLUMN_LAYOUT_SIZE = 980;

const getMyPostsPagePostSize = ({ state, section, containerWidth, layoutType }) => {
  const itemSize = getPGLayoutSettingsConstant(layoutType, DEFAULT_PG_LAYOUT_ITEM_SIZE, section);
  const pageBorderWidth = getMyPostsPageBorderWidth(state);
  const layoutWidth = itemSize - pageBorderWidth * 2;
  if (typeof containerWidth !== 'number') {
    return layoutWidth;
  }
  return Math.min(containerWidth, layoutWidth);
};

export const getLayoutPostSize = ({ state, section, hostWidth, rootWidth, layoutMargins, layoutType }) => {
  section = section || getSection(state);

  layoutType = layoutType || getLayoutType(state, section);
  const layoutName = getLayoutTypeById(layoutType);

  const layoutPGSideBySide = isLayoutNamePGSideBySide(layoutName);
  const layoutPGOneColumn = isLayoutNamePGOneColumn(layoutName);
  const width = isNumber(hostWidth) ? hostWidth : isNumber(rootWidth) ? rootWidth : null;

  if (section === SECTION_MY_POSTS) {
    return getMyPostsPagePostSize({ state, section, containerWidth: width, layoutType });
  }

  if (layoutPGSideBySide || layoutPGOneColumn) {
    const layoutSize = layoutPGSideBySide ? PG_SIDE_BY_SIDE_LAYOUT_SIZE : PG_ONE_COLUMN_LAYOUT_SIZE;
    const layoutPostSize = width !== null && width < layoutSize ? width : layoutSize;
    const postSize = layoutMargins ? layoutPostSize - layoutMargins * 2 : layoutPostSize;

    return postSize;
  }

  return getAppSettingsValue({
    state,
    key: getLayoutPostSizeParamPath(section, layoutName, getUseMobileLayoutSettings(state)),
    fallback: getPGLayoutSettingsConstant(layoutType, DEFAULT_PG_LAYOUT_ITEM_SIZE, section),
  });
};

const getPGLayoutAppSetting =
  (paramPathResolver, fallBackSetting, mobileFallBackSetting) =>
  ({ state, section, layoutType }) => {
    section = section || getSection(state);
    const useMobileLayoutSettings = getUseMobileLayoutSettings(state);
    layoutType = layoutType || getLayoutType(state, section, useMobileLayoutSettings);
    const layoutName = getLayoutTypeById(layoutType);

    return getAppSettingsValue({
      state,
      key: paramPathResolver(section, layoutName, useMobileLayoutSettings),
      fallback: getPGLayoutSettingsConstant(
        layoutType,
        (useMobileLayoutSettings && mobileFallBackSetting) || fallBackSetting,
        section,
      ),
    });
  };

export const getLayoutCardsRowType = getPGLayoutAppSetting(
  getLayoutCardsRowTypeParamPath,
  DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE,
);
export const getLayoutPostsPerRow = getPGLayoutAppSetting(
  getLayoutPostsPerRowParamPath,
  DEFAULT_PG_LAYOUT_POSTS_PER_ROW,
);

export const getLayoutSpacing = getPGLayoutAppSetting(
  getLayoutSpacingParamPath,
  DEFAULT_PG_LAYOUT_SPACING,
  DEFAULT_PG_LAYOUT_SPACING_MOBILE,
);

export const getLayoutImageRatio = getPGLayoutAppSetting(
  getLayoutImageRatioParamPath,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE,
);

export const getLayoutImageAlignment = getPGLayoutAppSetting(
  getLayoutImageAlignmentParamPath,
  DEFAULT_PG_IMAGE_ALIGNMENT,
);

export const getLayoutContentHeight = getPGLayoutAppSetting(
  getLayoutContentHeightParamPath,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
);

export const getLayoutContentHeightType = getPGLayoutAppSetting(
  getLayoutContentHeightTypeParamPath,
  DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE,
);

export const getLayoutMargins = getPGLayoutAppSetting(
  getLayoutMarginsParamPath,
  DEFAULT_PG_LAYOUT_MARGINS,
  DEFAULT_PG_LAYOUT_MARGINS_MOBILE,
);

export const getIsConfigurableLineClampingEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_CONFIGURABLE_LINE_CLAMPING_ENABLED_PATH,
    fallback: false,
  });

export const getLayoutTitleLineCount = (props) => {
  if (getIsConfigurableLineClampingEnabled(props.state)) {
    return getPGLayoutAppSetting(getLayoutTitleLineCountParamPath, DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT)(props);
  } else {
    return getPGLayoutAppSetting(getLayoutTitleLineCountParamPath)(props);
  }
};

export const getLayoutDescriptionLineCount = (props) => {
  if (getIsConfigurableLineClampingEnabled(props.state)) {
    return getPGLayoutAppSetting(
      getLayoutDescriptionLineCountParamPath,
      DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT,
    )(props);
  } else {
    return getPGLayoutAppSetting(getLayoutDescriptionLineCountParamPath)(props);
  }
};

export const getLayoutSidesPadding = getPGLayoutAppSetting(
  getLayoutSidesPaddingParamPath,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
);

export const getLayoutImageResizingMode = getPGLayoutAppSetting(
  getLayoutImageResizingModeParamPath,
  DEFAULT_PG_IMAGE_RESIZING_MODE,
);

export const getLayoutImageWidth = getPGLayoutAppSetting(getLayoutImageWidthParamPath, DEFAULT_PG_IMAGE_WIDTH);

export const getLayoutImageCropType = getPGLayoutAppSetting(
  getLayoutImageCropTypeParamPath,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
);

export const getLayoutImageProportions = getPGLayoutAppSetting(
  getLayoutImageProportionsParamPath,
  DEFAULT_PG_IMAGE_PROPORTIONS,
);

const getPresetColor = (state, presetName) =>
  get(
    find(getColorPresets(state), ({ reference }) => reference === presetName),
    'value',
  );

const toValueObj = createSelector(
  (value) => value,
  (value) => ({ value }),
);
export const getFeedBorderColor = ({ state, section }) =>
  getAppSettingsValue({
    state,
    key: getUseMobileDesignSettings(state) ? getMobileFeedBorderColorPath(section) : getFeedBorderColorPath(section),
    fallback: toValueObj(getPresetColor(state, 'color-2')),
  });

export const getIsTagLabelEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_TAG_LABEL_ENABLED_PATH,
    fallback: false,
  });

export const getTagLayout = (state) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileLayoutSettingsEnabled.appSettingsPath,
  );

  return getAppSettingsValue({
    state,
    key: useMobileSettings ? TAG_LAYOUT_MOBILE_PATH : TAG_LAYOUT_PATH,
    fallback: DEFAULT_TAG_LAYOUT,
  });
};

export const getUseTagMobileSettings = (state, key) => getIsMobile(state) && getIsMobileSettingEnabled(state, key);

const getTagLayoutParamsByDeviceType = (state) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileLayoutSettingsEnabled.appSettingsPath,
  );

  return getTagLayoutParams(getTagLayout(state), useMobileSettings);
};

const getTagDisplayParams = (state) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileDisplaySettingsEnabled.appSettingsPath,
  );

  return useMobileSettings ? TAG_DISPLAY_PARAMS_MOBILE : TAG_DISPLAY_PARAMS;
};

export const getTagAlignment = (state) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.tagAlignment.appSettingsPath,
    fallback: layoutParams.tagAlignment.defaultAlignment,
  });
};

export const getTagVerticalSpacing = (state) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.verticalSpacing.appSettingsPath,
    fallback: layoutParams.verticalSpacing.defaultSpacing,
  });
};

export const getTagHorizontalSpacing = (state) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.horizontalSpacing.appSettingsPath,
    fallback: layoutParams.horizontalSpacing.defaultSpacing,
  });
};

export const getNumberOfTags = (state) => {
  const { numberOfTags } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: numberOfTags.appSettingsPath,
    fallback: numberOfTags.defaultValue,
  });
};

export const getTagOrder = (state) => {
  const { orderTags } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: orderTags.appSettingsPath,
    fallback: orderTags.defaultValue,
  });
};

export const getTagShowPostCount = (state) => {
  const { showPostCount } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });
};

export const getCanSeeMoreButton = createSelector(
  [getIsMoreOptionsMenuEnabled, getIsMemberAreaInstalled, secondParam],
  (isMoreOptionsMenuEnabled, isMemberAreaInstalled, canSee) => (post) => {
    if (!isMoreOptionsMenuEnabled) {
      return false;
    } else {
      const postActions = getPostActions({
        post,
        canSee,
        enableShare: true,
        enableSubscribe: isMemberAreaInstalled,
      });
      return Boolean(postActions.length);
    }
  },
);

export const getIsCategoryLabelEnabled = (state) =>
  getDisplayToggle({
    state,
    key: IS_CATEGORY_LABEL_ENABLED_PATH,
    mobileKey: IS_CATEGORY_LABEL_ENABLED_MOBILE_PATH,
    wixParam: IS_CATEGORY_LABEL_ENABLED,
  });

export const getLiveSiteUrl = (state) => get(state, 'topology.baseUrl');

export const getContentAlignment = (state, isRTL) =>
  getAppSettingsValue({
    state,
    key: CONTENT_ALIGNMENT_PATH,
    fallback: isRTL ? CONTENT_ALIGNMENTS.right : CONTENT_ALIGNMENTS.left,
  });

export const getMobileContentAlignment = (state, isRTL) =>
  getAppSettingsValue({
    state,
    key: CONTENT_ALIGNMENT_MOBILE_PATH,
    fallback: isRTL ? CONTENT_ALIGNMENTS.right : CONTENT_ALIGNMENTS.left,
  });

export const getIsPostDesignInFullPostEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_POST_DESIGN_IN_FULL_POST_ENABLED_PATH,
    fallback: false,
  });
